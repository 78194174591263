import {
    fetchApiHeaders,
    globalURL
} from "../../helpers/constants";


const getData = () => dispatch => {
    dispatch({
        type: "GET_CLIENTS"
    })

    fetch(globalURL + "/customerComment/getAll", {
        method: 'POST',
        headers: {
            ...fetchApiHeaders.POST
        }
    })
        .then((result) => result.json())
        .then(async (result) => {
            if (result.success) {
                dispatch({
                    type: "FETCH_CLIENTS",
                    payload: result.data
                })
            } else {
                dispatch({
                    type: "ERROR_CLIENTS",
                    payload: result
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: "ERROR_CLIENTS",
                payload: error
            });
        })
}


export {
    getData
}