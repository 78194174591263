import React, { Fragment } from "react";
import { multilanguage } from "redux-multilanguage";
import { connect } from 'react-redux';

function About({ strings }) {
    return strings && strings.about && strings.about !== null ? (
        <Fragment>
            {/* About start */}
            <section id="aboutus" className="bg-light-gray3">
                <div className="container">
                    <div className="row">
                        <div className="container">
                            <div className="main-title style-two d-flex justify-content-md-around align-items-center flex-column flex-md-row text-center text-md-left wow fadeIn" data-wow-delay="300ms">
                                <div className="mb-4 mb-md-0">
                                    <h5>{strings.about.title}</h5>
                                    <h2>{strings.about.header}</h2>
                                    {/* <a  className="btn-setting btn-scale btn-blue text-white">{strings.about.buttonText}</a> */}
                                </div>
                                <div className="ml-md-4 pl-md-2">
                                    <p className="mb-4">{strings.about.text}</p>
                                    <p>{strings.about.subText}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                        {
                            strings.about.buttons.map((value, index) => {
                                return <div key={index} className="col-md-3 col-sm-12 mb-xs-2rem">
                                    <div className="about-box center-block wow zoomIn" data-wow-delay="400ms">
                                        <div className="about-opacity-icon"> <i className={value.icon} aria-hidden="true" /> </div>
                                        <div className="about-main-icon pb-4">
                                            <i className={value.icon} aria-hidden="true" />
                                        </div>
                                        <h5 className="mb-0">{value.text}</h5>
                                    </div>
                                </div>
                            })
                        }
                    </div> */}
                </div>
            </section>
            {/* About ends */}

        </Fragment>
    ) : null;
}

const mapStateToProps = state => {
    return {
        home: state.home
    }
}

export default connect(mapStateToProps, {

})(multilanguage(About));
