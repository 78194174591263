import React, { Fragment } from "react";
import { multilanguage } from "redux-multilanguage";
import { connect } from 'react-redux';
import ChangeLanguage from "./ChangeLanguageTrigger";

function Header({ strings, currentLanguageCode, dispatch }) {
    return strings && strings.menu && strings.menu !== null ? (
        <Fragment>
            {/* Header start */}
            <header>
                <nav className="navbar navbar-top-default navbar-expand-lg static-nav transparent-bg nav-bottom-line">
                    <div className="container">
                        <a className="logo" >
                            <img src="consulting/img/logo.png" alt="logo" title="Logo" className="logo-default" />
                            <img src="consulting/img/logo2.png" alt="logo" title="Logo" className="logo-scrolled" />
                        </a>
                        <div className="collapse navbar-collapse d-none d-lg-block">
                            <ul className="nav navbar-nav ml-auto mr-4">
                                <li className="nav-item"> <a href="#home" className="scroll nav-link">{"ANASAYFA"}</a></li>
                                <li className="nav-item"> <a href="#aboutus" className="scroll nav-link">{"HAKKIMIZDA"}</a></li>
                                <li className="nav-item"> <a href="#cases" className="scroll nav-link">{"ÇALIŞMALAR"}</a></li>
                                <li className="nav-item"> <a href="#partners" className="scroll nav-link">{"PARTNERLERİMİZ"}</a></li>
                                <li className="nav-item"> <a href="#contact" className="scroll nav-link">{"İLETİŞİM"}</a></li>
                            </ul>
                        </div>
                        <a href="#request" className="scroll btn-setting btn-scale btn-green text-white d-none d-lg-block">{"BİZE YAZIN"}</a>
                        {/* <ChangeLanguage
                            currentLanguageCode={currentLanguageCode}
                            dispatch={dispatch}
                        /> */}
                        {/* side menu open button */}
                        <a className="menu_bars d-inline-block menu-bars-setting" id="sidemenu_toggle">
                            <div className="menu-lines">
                                <span />
                                <span />
                                <span />
                            </div>
                        </a>
                    </div>
                </nav>
                <div className="side-menu">
                    <div className="inner-wrapper nav-icon">
                        <span className="btn-close" id="btn_sideNavClose"></span>
                        <nav className="side-nav w-100">
                            <div className="navbar-nav">
                                <a href="#home" className="scroll nav-link">{"ANASAYFA"}</a>
                                <a href="#aboutus" className="scroll nav-link">{"HAKKIMIZDA"}</a>
                                <a href="#cases" className="scroll nav-link">{"ÇALIŞMALAR"}</a>
                                <a href="#partners" className="scroll nav-link">{"PARTNERLERİMİZ"}</a>
                                <a href="#contact" className="scroll nav-link">{"İLETİŞİM"}</a>
                                <a href="#request" className="scroll nav-link">{"BİZE YAZIN"}</a>
                                <a className="menu-line"><i className="fa fa-angle-right font-14" aria-hidden="true"></i></a>
                            </div>
                        </nav>

                        <div className="side-footer text-white w-100">
                            <ul className="social-icons-simple">
                                {
                                    strings.menu.socialMediaLinks ? Object.keys(strings.menu.socialMediaLinks).map((val, index) => {
                                        return String(strings.menu.socialMediaLinks[val]).trim().length > 0 ? <li key={index} className="side-menu-icons"><a target="_blank" href={strings.menu.socialMediaLinks[val]}><i className={"fab fa-" + val + " color-white"}></i> </a> </li> : null
                                    }) : null
                                }
                            </ul>
                            <p className="text-white">&copy; {new Date().getFullYear()} NRN PHARMA.{" "}{strings.menu.rights}</p>
                        </div>
                    </div>
                </div>
                <a id="close_side_menu" href="javascript:void(0);" />
                {/*Side Menu*/}
            </header>
            {/* Header end */}

        </Fragment>
    ) : null;
}

const mapStateToProps = state => {
    return {
        home: state.home
    }
}

export default connect(mapStateToProps, {

})(multilanguage(Header));
