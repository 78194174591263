import React, { Fragment } from "react";
import { multilanguage } from "redux-multilanguage";
import { connect } from 'react-redux';

function Feature({ strings }) {
    return strings && strings.description && strings.description !== null ? (
        <Fragment>
            {/* Service start */}
            <section className="half-section p-0 bg-change bg-green">
                <h2 className="d-none">heading</h2>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 p-lg-0 order-lg-2">
                            <div className="split-container-setting style-three text-center text-lg-left">
                                <div className="main-title mb-5 text-lg-left wow fadeIn" data-wow-delay="300ms">
                                    <h5 className="font-18">{strings.description.title}</h5>
                                    <h2 className="mb-0">
                                        {
                                            strings.description.header.map((item, index) => {
                                                return item.isBold ? <b key={index}>{" "}{item.text}{" "}</b> : <label key={index}>{" "}{item.text}{" "}</label>
                                            })
                                        }
                                    </h2>
                                </div>
                                <p className="color-white mb-5">{strings.description.text}</p>
                                {/* <a  className="btn-setting btn-transparent btn-hvr-blue color-white">{strings.description.buttonText}</a> */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 p-0">
                            <div className="hover-effect">
                                <img alt="stats" src="consulting/img/1.jpg" className="about-img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Service ends */}

        </Fragment>
    ) : null
}

const mapStateToProps = state => {
    return {
        home: state.home
    }
}

export default connect(mapStateToProps, {

})(multilanguage(Feature));
