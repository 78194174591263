import React, { Fragment, useEffect } from "react";
import { multilanguage } from "redux-multilanguage";
import { connect } from 'react-redux';
import { globalURL } from "../../helpers/constants";
import moment from "../../helpers/moment";
import { getData } from "../../redux/actions/clientsActions";

function Clients({ getData, strings, clientsState }) {
    useEffect(() => {
        getData();
        return () => {
            // will unMount 
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return strings && strings.clients && strings.clients !== null && clientsState.data.length > 0 ? (
        <Fragment>
            {/* Clients start */}
            <section id="clients" className="bg-blue">
                <h2 className="d-none">heading</h2>
                <div className="container">
                    <div className="row align-items-center position-relative">
                        <div className="col-lg-7 col-md-8 col-12 mb-5 m-md-0">
                            <div className="main-title text-center text-md-left mb-4 wow fadeIn" data-wow-delay="300ms">
                                <h5 className="text-white">{strings.clients.title}</h5>
                                <h2 className="text-white ml-0">
                                    {
                                        strings.clients.header.map((item, index) => {
                                            return item.isBold ? <b key={index}>{" "}{item.text}{" "}</b> : <label key={index}>{" "}{item.text}{" "}</label>
                                        })
                                    }
                                </h2>
                            </div>
                            <div className="testimonial-for testimonial-one wow fadeInLeft" data-wow-delay="800ms">
                                {
                                    clientsState.data.map((item, key) => {
                                        return <div key={key} className="item testimonial-item">
                                            <div className="testimonial-text">
                                                <div className="quote d-flex justify-content-start mb-4"><i className="fa fa-quote-left" /></div>
                                                <p className="color-white font-16 font-weight-normal mb-4">{item.commentText}</p>
                                                <div className="quote d-flex justify-content-end mb-3"><i className="fa fa-quote-right" /></div>
                                                <div className="testimonial-post">
                                                    <div className="text-content">
                                                        <h4 className="color-white text-capitalize mb-1">{item.name}{" "}{item.surname}</h4>
                                                        <p className="color-white font-italic mb-2"> {moment(item.createdAt).format('LL')}</p>
                                                        <div className="rating">
                                                            {
                                                                Array.from({ length: Math.round(item.point) > 5 ? 5 : Math.round(item.point) }, (x, i) => i).map((val, index) => {
                                                                    return <i key={index} className="fa fa-star" />
                                                                })

                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })

                                }
                            </div>
                        </div>
                        <div className="col-lg-4 offset-lg-1 col-md-4 col-12">
                            <div className="testimonial-nav testimonial-one mb-0 wow fadeInRight" data-wow-delay="800ms">
                                {
                                    clientsState.data.map((item, index) => {
                                        return <div key={index} className="item testimonial-item">
                                            <div className="testimonial-person pt-5 d-flex justify-content-center mx-auto">
                                                <div className="testimonial-post">
                                                    <div className="post mb-4 mx-auto"><img src={item.image && item.image !== null ? globalURL + "/images/" + item.image.name : "assets/images/default.jpg"} alt="NRN Pharma" /></div>
                                                    <div className="text-content text-center">
                                                        <h4 className="color-white text-capitalize mb-1">{item.name}{" "}{item.surname}</h4>
                                                        <p className="color-white mb-2rem"> {item.title}<br />{item.company}</p>
                                                        <div className="testimonial-social-icons d-flex justify-content-around">
                                                            <a ><i className="fab fa-facebook-square" /></a>
                                                            <a ><i className="fab fa-twitter-square" /></a>
                                                            <a ><i className="fab fa-linkedin" /></a>
                                                            <a ><i className="fab fa-pinterest-square" /></a>
                                                            <a ><i className="fab fa-vimeo" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Clients ends */}

        </Fragment>
    ) : null;
}

const mapStateToProps = state => {
    return {
        clientsState: state.clients
    }
}

export default connect(mapStateToProps, {
    getData
})(multilanguage(Clients));
