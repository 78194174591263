import React, { Fragment } from "react";
import { multilanguage } from "redux-multilanguage";
import { connect } from 'react-redux';

function Footer({ strings }) {
    return strings && strings.menu && strings.menu !== null ? (
        <Fragment>
            {/* Footer starts */}
            <footer className="bg-light-gray footer-transform-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 text-center">
                            <p className="copyrights px-0 py-5">{new Date().getFullYear()} © NRN PHARMA.{" "}{strings.menu.rights}</p>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer ends */}

        </Fragment>
    ) : null;
}

const mapStateToProps = state => {
    return {
        home: state.home
    }
}

export default connect(mapStateToProps, {

})(multilanguage(Footer));
