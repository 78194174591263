import React, { Fragment, useEffect } from "react";
import { multilanguage } from "redux-multilanguage";
import { connect } from 'react-redux';
import { globalURL } from "../../helpers/constants";
import { getData } from "../../redux/actions/referencesActions";

function Testimonial({ getData, strings, references }) {
    useEffect(() => {
        getData();
        return () => {
            // will unMount 
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <Fragment>
            {/* Brands starts */}
            <section id="partners" className="bg-gray brand-transform-padding">
                <div className="section-padding">
                    <h2 className="d-none">heading</h2>
                    <div className="container">
                        <div className="main-title">
                            <h2>
                                <label>Değerli</label>  <b>Partnerlerimiz</b>
                            </h2>
                        </div>
                        <div className="brand-carousel owl-carousel owl-theme">
                            <div className="item custom-shadow">
                                <img src={process.env.PUBLIC_URL + "/assets/images/agarta.jpg"} alt="NRN Pharma Cosmetics & Health" />
                            </div>
                            <div className="item custom-shadow">
                                <img src={process.env.PUBLIC_URL + "/assets/images/erica.jpg"} alt="NRN Pharma Cosmetics & Health" />
                            </div>
                            <div className="item custom-shadow">
                                <img src={process.env.PUBLIC_URL + "/assets/images/etika.jpg"} alt="NRN Pharma Cosmetics & Health" />
                            </div>
                            <div className="item custom-shadow">
                                <img src={process.env.PUBLIC_URL + "/assets/images/metapharma.jpg"} alt="NRN Pharma Cosmetics & Health" />
                            </div>
                            <div className="item custom-shadow">
                                <img src={process.env.PUBLIC_URL + "/assets/images/rckozmetic.jpg"} alt="NRN Pharma Cosmetics & Health" />
                            </div>
                            <div className="item custom-shadow">
                                <img src={process.env.PUBLIC_URL + "/assets/images/intense-keratin.jpg"} alt="NRN Pharma Cosmetics & Health" />
                            </div>
                            <div className="item custom-shadow">
                                <img src={process.env.PUBLIC_URL + "/assets/images/kumsal-grup.jpg"} alt="NRN Pharma Cosmetics & Health" />
                            </div>
                            <div className="item custom-shadow">
                                <img src={process.env.PUBLIC_URL + "/assets/images/deniz-yilmaz.jpg"} alt="NRN Pharma Cosmetics & Health" />
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* Brands ends */}

        </Fragment>
    );
}

const mapStateToProps = state => {
    return {
        references: state.references
    }
}

export default connect(mapStateToProps, {
    getData
})(multilanguage(Testimonial));