import React, { Fragment, useEffect } from "react";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { globalURL, fetchApiHeaders } from "./helpers/constants";
import {
  Header,
  Footer,
  Home,
  Feature,
  Contact,
  About,
  Clients,
  Testimonial,
  Cases,
  Statistic,
  Request,
} from "./layout/index";

function App(props) {
  useEffect(() => {
    fetch(globalURL + "/language/getAll", {
      method: 'POST',
      headers: {
        ...fetchApiHeaders.POST
      }
    })
      .then((result) => result.json())
      .then(async (languageResult) => {
        if (languageResult.success) {
          props.dispatch(
            loadLanguages({
              languages: languageResult.languages
            })
          );
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, []);
  return <Fragment>
      <Header />
      <Home />
      <About />
      {/* <Statistic /> */}
      <Feature />
      <Cases />
      <Request />
      <Clients />
      <Testimonial />
      <Contact />
      <Footer />
  </Fragment>

}

export default connect()(multilanguage(App));
