import React, { Fragment, useEffect } from "react";
import { multilanguage } from "redux-multilanguage";
import { connect } from 'react-redux';
import { globalURL } from "../../helpers/constants";
import moment from "../../helpers/moment";
import { getData } from "../../redux/actions/caseActions";

function Cases({ getData, strings, casesState, currentLanguageCode }) {
    useEffect(() => {
        getData();
        return () => {
            // will unMount 
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    return <Fragment>
        {/* Cases start */}
        <section id="cases">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div class="main-title wow fadeIn" data-wow-delay="300ms">
                            <h2> Bilimsel <b>Çalışmalarımız</b> </h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div id="js-grid-slider-projects" className="cbp">
                            {
                                casesState.data.map((item, index) => {
                                    return <div key={index} className="cbp-item">
                                        <div className="cbp-caption">
                                            <div className="cbp-caption-defaultWrap">
                                                {
                                                    item.image && item.image.length > 0 ?
                                                        <img src={globalURL + "/images/" + item.image[0].name} alt="NRN Pharma Cosmetics & Health" /> : null
                                                }

                                            </div>
                                            <a href={"#js-singlePage-container/project-" + item.id} className="cbp-caption-activeWrap cbp-singlePage">
                                                <div className="cbp-l-caption-alignCenter">
                                                    <div className="cbp-l-caption-body">
                                                        <div className="cases-image-inner">
                                                            <span className="cases-line top" />
                                                            <span className="cases-line top-right" />
                                                            <span className="cases-line bottom" />
                                                            <span className="cases-line bottom-left" />
                                                            <h6 className="text-white text-uppercase font-14 font-secondary font-weight-light">{item.title.tr}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                })
                            }

                        </div>
                        <div id="js-singlePage-container" className="d-none">
                            {
                                casesState.data.map((item, index) => {
                                    return <div key={index}>
                                        <div className="cbp-l-project-title"><span className="font-weight-600">{item.title.tr}</span></div>
                                        <div className="cbp-l-project-subtitle">{item.description.tr}</div>
                                        <div className="cbp-slider">
                                            <ul className="cbp-slider-wrap">
                                                {
                                                    item.image ?
                                                        item.image.map((img, iIndex) => {
                                                            return <li key={iIndex} className="cbp-slider-item">
                                                                <img src={globalURL + "/images/" + img.name} alt="NRN Pharma Cosmetics & Health" />
                                                            </li>
                                                        }) : null
                                                }
                                            </ul>
                                        </div>
                                        <div className="cbp-l-project-container">
                                            <div className="cbp-l-project-desc">
                                                <div className="cbp-l-project-desc-text">{item.text.tr}</div>
                                            </div>
                                            <div className="cbp-l-project-details">
                                                <ul className="cbp-l-project-details-list mb-4">
                                                    <li><strong>{"Açıklama"}</strong>{item.helperText.tr}</li>
                                                    <li><strong>{"Tarih"}</strong>{moment(item.createdAt).format('L')}</li>
                                                    <li><strong>{"Kategori"}</strong>{item.category.tr}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        {
                                            casesState.data.filter((xItem) => {
                                                return xItem.id !== item.id
                                            }).length > 0 ? <div className="cbp-l-project-container">
                                                <div className="cbp-l-project-related">
                                                    <div className="cbp-l-project-desc-title"><span>{"Diğer Çalışmalarımız"}</span></div>
                                                    <ul className="cbp-l-project-related-wrap">
                                                        {
                                                            casesState.data.filter((xItem) => {
                                                                return xItem.id !== item.id
                                                            }).map((sItem, sIndex) => {
                                                                return <li key={sIndex} className="cbp-l-project-related-item">
                                                                    <a href={"#js-singlePage-container/project-" + sItem.id} className="cbp-singlePage cbp-l-project-related-link" rel="nofollow">
                                                                        {
                                                                            sItem.image && sItem.image.length > 0 ?
                                                                                <img src={globalURL + "/images/" + sItem.image[0].name} alt="NRN Pharma Cosmetics & Health" /> : null
                                                                        }
                                                                        <div className="cbp-l-project-related-title">{sItem.title.tr}</div>
                                                                    </a>
                                                                </li>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div> : null
                                        }

                                        <br /><br /><br />
                                    </div>

                                })
                            }

                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* Cases ends */}

    </Fragment>;
}

const mapStateToProps = state => {
    return {
        casesState: state.cases
    }
}

export default connect(mapStateToProps, {
    getData
})(multilanguage(Cases));
