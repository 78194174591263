const initialState = {
    data: [],
    isFetching: false,
    error: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case "GET_REFERENCES":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_REFERENCES":
            return {
                ...state,
                isFetching: false,
                data: action.payload
            }
        case "ERROR_REFERENCES":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        default:
            return state
    }

}
