import React, { Fragment, useEffect } from "react";
import { multilanguage } from "redux-multilanguage";
import { connect } from 'react-redux';
import { getHomeData } from "../../redux/actions/homeActions";
import { globalURL } from "../../helpers/constants";

function Home({ getHomeData, home, strings, currentLanguageCode }) {
    useEffect(() => {
        getHomeData();
        return () => {
            // will unMount 
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <Fragment>
        {/* Main Section start */}
        <section id="home" className="p-0 no-transition h-100vh">
            <h2 className="d-none">heading</h2>
            {/*Main Slider*/}
            <div id="revo_thumbs_wrapper" className="rev_slider_wrapper fullwidthbanner-container">
                <div id="secondary-banner" className="rev_slider fullwidthabanner" style={{ display: 'none' }} data-version="5.4.1">
                    <ul>
                        <li data-index={"rs-00"} data-transition="fade" data-slotamount="default" data-easein="Power3.easeInOut" data-easeout="Power3.easeInOut" data-masterspeed={2000} data-fsmasterspeed={1500} data-param1>
                            {/* MAIN IMAGE */}
                            <img src={"https://api.nrnpharma.com/images/ZDO44ZucB7Fco4CKUvpcT.jpg"} alt="NRN Pharma Cosmetcis & Health | Keratin" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" data-bgparallax={0} data-no-retina />
                            <div className="bg-overlay bg-black opacity-7" />
                            {/* LAYER NR. 2 */}
                            <div className="tp-caption tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['-135','-135','-135','-120']" data-fontsize="['55','55','55','35']" data-whitespace="nowrap" data-responsive_offset="on" data-width="['none','none','none','none']" data-type="text" data-textalign="['center','center','center','center']" data-frames="[{&quot;delay&quot;:0,&quot;speed&quot;:2000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;auto:auto;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-start={1000} data-splitin="none" data-splitout="none" style={{ zIndex: 1, fontWeight: 300, color: '#ffffff', fontFamily: '"Raleway", sans-serif', textTransform: 'capitalize' }}>
                                NRN PHARMA
                            </div>
                            {/* LAYER NR. 3 */}
                            <div className="tp-caption tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['-70','-70','-70','-55']" data-fontsize="['55','55','55','35']" data-whitespace="nowrap" data-responsive_offset="on" data-width="['none','none','none','none']" data-type="text" data-textalign="['center','center','center','center']" data-frames="[{&quot;delay&quot;:1000,&quot;speed&quot;:1500,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;auto:auto;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-start={1500} data-splitin="none" data-splitout="none" style={{ zIndex: 1, fontWeight: 500, lineHeight: '60px', color: '#ffffff', fontFamily: '"Raleway", sans-serif', textTransform: 'capitalize' }}>
                                KeraPEPT™
                            </div>
                            {/* LAYER NR. 4 */}
                            <div className="tp-caption tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['5','5','5','15']" data-fontsize="['55','55','55','35']" data-whitespace="nowrap" data-responsive_offset="on" data-width="['none','none','none','none']" data-type="text" data-textalign="['center','center','center','center']" data-frames="[{&quot;delay&quot;:1500,&quot;speed&quot;:1500,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:[100%];opacity:1;&quot;,&quot;mask&quot;:&quot;x:[-100%];y:0;s:inherit;e:inherit;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power4.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:[100%];opacity:1;&quot;,&quot;mask&quot;:&quot;x:[-100%];y:0;s:inherit;e:inherit;&quot;,&quot;ease&quot;:&quot;Power4.easeInOut&quot;}]" data-start={2000} data-splitin="none" data-splitout="none" style={{ zIndex: 1, fontWeight: 300, lineHeight: '60px', color: '#ffffff', fontFamily: '"Raleway", sans-serif', textTransform: 'capitalize' }}>
                                Toz Form
                            </div>
                            {/* LAYER NR. 5 */}
                            <div className="tp-caption tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['75','75','75','75']" data-fontsize="['22','22','24','16']" data-whitespace="nowrap" data-responsive_offset="on" data-width="['none','none','none','none']" data-type="text" data-textalign="['center','center','center','center']" data-transform_idle="o:1;" data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;" data-transform_out="s:20;e:Power4.easeInOut;s:20;e:Power4.easeInOut;" data-start={800} data-splitin="none" data-splitout="none" style={{ zIndex: 4, fontWeight: 200, lineHeight: '60px', color: '#ffffff', fontFamily: '"Raleway", sans-serif', textTransform: 'capitalize' }}>
                                Hidrolize Keratin Çözüm Ortağınız
                            </div>
                            {/* LAYER NR. 6 */}
                            <div className="tp-caption tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['145','145','145','145']" data-width="['180','180','180','180']" data-frames="[{&quot;delay&quot;:600,&quot;speed&quot;:2000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;sX:1;sY:1;opacity:0;fb:40px;&quot;,&quot;to&quot;:&quot;o:1;fb:0;&quot;,&quot;ease&quot;:&quot;Power4.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;fb:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-textalign="['center','center','center','center']" style={{ zIndex: 99, maxWidth: '960px' }}>
                                <a href="/#aboutus" className="scroll btn-setting btn-scale btn-green text-white">Daha Fazla</a>
                            </div>
                        </li>
                        <li data-index={"rs-01"} data-transition="fade" data-slotamount="default" data-easein="Power3.easeInOut" data-easeout="Power3.easeInOut" data-masterspeed={2000} data-fsmasterspeed={1500} data-param1>
                            {/* MAIN IMAGE */}
                            <img src={"https://api.nrnpharma.com/images/tJXMS0sSmzBBvKm100WQf.jpg"} alt="NRN Pharma Cosmetcis & Health | Keratin" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" data-bgparallax={0} data-no-retina />
                            <div className="bg-overlay bg-black opacity-7" />
                            {/* LAYER NR. 2 */}
                            <div className="tp-caption tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['-135','-135','-135','-120']" data-fontsize="['55','55','55','35']" data-whitespace="nowrap" data-responsive_offset="on" data-width="['none','none','none','none']" data-type="text" data-textalign="['center','center','center','center']" data-frames="[{&quot;delay&quot;:0,&quot;speed&quot;:2000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;auto:auto;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-start={1000} data-splitin="none" data-splitout="none" style={{ zIndex: 1, fontWeight: 300, color: '#ffffff', fontFamily: '"Raleway", sans-serif', textTransform: 'capitalize' }}>
                                NRN PHARMA
                            </div>
                            {/* LAYER NR. 3 */}
                            <div className="tp-caption tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['-70','-70','-70','-55']" data-fontsize="['55','55','55','35']" data-whitespace="nowrap" data-responsive_offset="on" data-width="['none','none','none','none']" data-type="text" data-textalign="['center','center','center','center']" data-frames="[{&quot;delay&quot;:1000,&quot;speed&quot;:1500,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;auto:auto;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-start={1500} data-splitin="none" data-splitout="none" style={{ zIndex: 1, fontWeight: 500, lineHeight: '60px', color: '#ffffff', fontFamily: '"Raleway", sans-serif', textTransform: 'capitalize' }}>
                                KeraPEPT™
                            </div>
                            {/* LAYER NR. 4 */}
                            <div className="tp-caption tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['5','5','5','15']" data-fontsize="['55','55','55','35']" data-whitespace="nowrap" data-responsive_offset="on" data-width="['none','none','none','none']" data-type="text" data-textalign="['center','center','center','center']" data-frames="[{&quot;delay&quot;:1500,&quot;speed&quot;:1500,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:[100%];opacity:1;&quot;,&quot;mask&quot;:&quot;x:[-100%];y:0;s:inherit;e:inherit;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power4.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:[100%];opacity:1;&quot;,&quot;mask&quot;:&quot;x:[-100%];y:0;s:inherit;e:inherit;&quot;,&quot;ease&quot;:&quot;Power4.easeInOut&quot;}]" data-start={2000} data-splitin="none" data-splitout="none" style={{ zIndex: 1, fontWeight: 300, lineHeight: '60px', color: '#ffffff', fontFamily: '"Raleway", sans-serif', textTransform: 'capitalize' }}>
                                Sıvı Form
                            </div>
                            {/* LAYER NR. 5 */}
                            <div className="tp-caption tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['75','75','75','75']" data-fontsize="['22','22','24','16']" data-whitespace="nowrap" data-responsive_offset="on" data-width="['none','none','none','none']" data-type="text" data-textalign="['center','center','center','center']" data-transform_idle="o:1;" data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;" data-transform_out="s:20;e:Power4.easeInOut;s:20;e:Power4.easeInOut;" data-start={800} data-splitin="none" data-splitout="none" style={{ zIndex: 4, fontWeight: 200, lineHeight: '60px', color: '#ffffff', fontFamily: '"Raleway", sans-serif', textTransform: 'capitalize' }}>
                                Hidrolize Keratin Çözüm Ortağınız
                            </div>
                            {/* LAYER NR. 6 */}
                            <div className="tp-caption tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['145','145','145','145']" data-width="['180','180','180','180']" data-frames="[{&quot;delay&quot;:600,&quot;speed&quot;:2000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;sX:1;sY:1;opacity:0;fb:40px;&quot;,&quot;to&quot;:&quot;o:1;fb:0;&quot;,&quot;ease&quot;:&quot;Power4.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;fb:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-textalign="['center','center','center','center']" style={{ zIndex: 99, maxWidth: '960px' }}>
                                <a href="/#aboutus" className="scroll btn-setting btn-scale btn-green text-white">Daha Fazla</a>
                            </div>
                        </li>
                        <li data-index={"rs-02"} data-transition="fade" data-slotamount="default" data-easein="Power3.easeInOut" data-easeout="Power3.easeInOut" data-masterspeed={2000} data-fsmasterspeed={1500} data-param1>
                            {/* MAIN IMAGE */}
                            <img src={"https://api.nrnpharma.com/images/OMTLDfsxGoQPXv_fspevN.jpg"} alt="NRN Pharma Cosmetcis & Health | Keratin" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" data-bgparallax={0} data-no-retina />
                            <div className="bg-overlay bg-black opacity-7" />
                            {/* LAYER NR. 2 */}
                            <div className="tp-caption tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['-135','-135','-135','-120']" data-fontsize="['55','55','55','35']" data-whitespace="nowrap" data-responsive_offset="on" data-width="['none','none','none','none']" data-type="text" data-textalign="['center','center','center','center']" data-frames="[{&quot;delay&quot;:0,&quot;speed&quot;:2000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;auto:auto;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-start={1000} data-splitin="none" data-splitout="none" style={{ zIndex: 1, fontWeight: 300, color: '#ffffff', fontFamily: '"Raleway", sans-serif', textTransform: 'capitalize' }}>
                                NRN PHARMA
                            </div>
                            {/* LAYER NR. 3 */}
                            <div className="tp-caption tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['-70','-70','-70','-55']" data-fontsize="['55','55','55','35']" data-whitespace="nowrap" data-responsive_offset="on" data-width="['none','none','none','none']" data-type="text" data-textalign="['center','center','center','center']" data-frames="[{&quot;delay&quot;:1000,&quot;speed&quot;:1500,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;auto:auto;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-start={1500} data-splitin="none" data-splitout="none" style={{ zIndex: 1, fontWeight: 500, lineHeight: '60px', color: '#ffffff', fontFamily: '"Raleway", sans-serif', textTransform: 'capitalize' }}>
                                KeraPEPT™
                            </div>
                            {/* LAYER NR. 4 */}
                            <div className="tp-caption tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['5','5','5','15']" data-fontsize="['55','55','55','35']" data-whitespace="nowrap" data-responsive_offset="on" data-width="['none','none','none','none']" data-type="text" data-textalign="['center','center','center','center']" data-frames="[{&quot;delay&quot;:1500,&quot;speed&quot;:1500,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:[100%];opacity:1;&quot;,&quot;mask&quot;:&quot;x:[-100%];y:0;s:inherit;e:inherit;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power4.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;x:[100%];opacity:1;&quot;,&quot;mask&quot;:&quot;x:[-100%];y:0;s:inherit;e:inherit;&quot;,&quot;ease&quot;:&quot;Power4.easeInOut&quot;}]" data-start={2000} data-splitin="none" data-splitout="none" style={{ zIndex: 1, fontWeight: 300, lineHeight: '60px', color: '#ffffff', fontFamily: '"Raleway", sans-serif', textTransform: 'capitalize' }}>
                                Akışkan Form
                            </div>
                            {/* LAYER NR. 5 */}
                            <div className="tp-caption tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['75','75','75','75']" data-fontsize="['22','22','24','16']" data-whitespace="nowrap" data-responsive_offset="on" data-width="['none','none','none','none']" data-type="text" data-textalign="['center','center','center','center']" data-transform_idle="o:1;" data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;" data-transform_out="s:20;e:Power4.easeInOut;s:20;e:Power4.easeInOut;" data-start={800} data-splitin="none" data-splitout="none" style={{ zIndex: 4, fontWeight: 200, lineHeight: '60px', color: '#ffffff', fontFamily: '"Raleway", sans-serif', textTransform: 'capitalize' }}>
                                Hidrolize Keratin Çözüm Ortağınız
                            </div>
                            {/* LAYER NR. 6 */}
                            <div className="tp-caption tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['145','145','145','145']" data-width="['180','180','180','180']" data-frames="[{&quot;delay&quot;:600,&quot;speed&quot;:2000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;sX:1;sY:1;opacity:0;fb:40px;&quot;,&quot;to&quot;:&quot;o:1;fb:0;&quot;,&quot;ease&quot;:&quot;Power4.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;fb:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-textalign="['center','center','center','center']" style={{ zIndex: 99, maxWidth: '960px' }}>
                                <a href="/#aboutus" className="scroll btn-setting btn-scale btn-green text-white">Daha Fazla</a>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
            {/*Main Slider ends */}
        </section>
        {/* Main Section end */}

    </Fragment>
}

const mapStateToProps = state => {
    return {
        home: state.home
    }
}

export default connect(mapStateToProps, {
    getHomeData
})(multilanguage(Home));
