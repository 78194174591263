import React, { Fragment } from "react";
import { multilanguage } from "redux-multilanguage";
import { connect } from 'react-redux';

function Contact({ strings }) {
    return <Fragment>
        {/* Contact & Map starts */}
        <section id="contact" className="p-0 contact-transform position-absolute w-100">
            <div className="container contact-shadow">
                <div className="row mx-lg-0">
                    <div className="col-lg-6 col-md-6 col-sm-12 p-0">
                        <div className="contact-box box-shadow-contact">
                            <div className="main-title text-center text-md-left mb-4">
                                <h2 className="font-weight-normal">{"İletişim"}</h2>
                            </div>
                            <div className="text-center text-md-left">
                                {/*Address*/}
                                <p className="mb-3">{"NRN PHARMA® Kozmetik Sağlık Teknolojileri Doğal Ürünler Biyoteknoloji İthalat İhracat"}</p>
                                <p className="mb-3">{"İsmetpaşa Mah. Küçük Sanayi 27. Sok. No:1 İç Kapı No:1 Merkez / Çanakkale / Türkiye"}</p>
                                {/*Phone*/}
                                <p className="mb-3">
                                    {"E-posta"}{" : "}<a href="mailto:info@nrnpharma.com" className="color-black">info@nrnpharma.com</a> </p>

                                {/*Social Icon*/}
                                {/* <div className="address-social black">
                                        <ul className="list-unstyled">
                                            {
                                                strings.menu && strings.menu.socialMediaLinks ? Object.keys(strings.menu.socialMediaLinks).map((val, index) => {
                                                    return String(strings.menu.socialMediaLinks[val]).trim().length > 0 ?

                                                        <li key={index}><a className={String(val).split("-")[0] + "-bg-hvr"} target="_blank" href={strings.menu.socialMediaLinks[val]}><i className={"fab fa-" + val} aria-hidden="true" /></a></li>
                                                        : null
                                                }) : null
                                            }
                                        </ul>
                                    </div> */}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 p-0 col-map box-shadow-map">
                        <div id="google-map" class="bg-light-gray map"></div>
                    </div>
                </div>
            </div>
        </section>
        {/* Contact & Map ends */}

    </Fragment>
}

const mapStateToProps = state => {
    return {
        home: state.home
    }
}

export default connect(mapStateToProps, {

})(multilanguage(Contact));
