const globalURL = "https://api.nrnpharma.com";
const webPage = "https://nrnpharma.com";
const fetchApiHeaders = {
    POST: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
}

export {
    globalURL,
    fetchApiHeaders,
    webPage
}