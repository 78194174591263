import { combineReducers } from 'redux';
import {
    homeReducer,
    clientsReducer,
    referenceReducer,
    caseReducer,
} from "./importReducers";

import { createMultilanguageReducer } from "redux-multilanguage";

const rootReducer = combineReducers({
    multilanguage: createMultilanguageReducer({ currentLanguageCode: "tr" }),
    home: homeReducer,
    clients: clientsReducer,
    references: referenceReducer,
    cases: caseReducer
});

export default rootReducer;