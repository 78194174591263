import React, { Fragment } from "react";
import { multilanguage } from "redux-multilanguage";
import { connect } from 'react-redux';

function Stats({ strings }) {
    const { skills } = strings;
    return skills && skills !== null ? (
        <Fragment>
            {/* Stats start */}
            <section className="half-section p-0 bg-change bg-blue">
                <h2 className="d-none">heading</h2>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 p-0 order-lg-2">
                            <div className="hover-effect">
                                <img alt="stats" src="consulting/img/split-stats.jpg" className="about-img" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 p-lg-0">
                            <div className="split-container-setting stats style-three">
                                <div className="main-title mb-5 text-lg-left wow fadeIn" data-wow-delay="300ms">
                                    <h5 className="font-18">{skills.title}</h5>
                                    <h2>{
                                        skills.header.map((item, index) => {
                                            return item.isBold ? <b key={index}>{" "}{item.text}{" "}</b> : <label key={index}>{" "}{item.text}{" "}</label>
                                        })
                                    }</h2>
                                </div>
                                <ul className="text-left">
                                    {
                                        skills.chart.map((item, index) => {
                                            return <li key={index} className="custom-progress">
                                                <h6 className="font-18 mb-0 text-capitalize">{item.text}<span className="float-right"><b className="font-secondary font-weight-500 numscroller">{item.value}</b>%</span></h6>
                                                <div className="progress">
                                                    <div className="progress-bar bg-white" role="progressbar" aria-valuenow={item.value} aria-valuemin={0} aria-valuemax={100}>
                                                    </div>
                                                </div>
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Stats ends */}

        </Fragment>
    ) : null;
}

const mapStateToProps = state => {
    return {
        home: state.home
    }
}

export default connect(mapStateToProps, {

})(multilanguage(Stats));
