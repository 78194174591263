import React, { Fragment } from "react";
import { multilanguage } from "redux-multilanguage";
import { connect } from 'react-redux';

function Team({ strings, currentLanguageCode }) {


    return strings && strings.request && strings.request !== null ? (
        <Fragment>
            {/* Request start */}
            <section id="request" className="bg-light-gray half-section p-0">
                <h2 className="d-none">heading</h2>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="owl-carousel owl-theme owl-split">
                                <div className="item">
                                    <div className="image split-blog-scale">
                                        <img alt="NRN Pharma Cosmetics & Healthcare, Kerapept, Keratin" src="consulting/img/logo_kerapept.jpg" className="about-img" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="image split-blog-scale">
                                        <img alt="NRN Pharma Cosmetics & Healthcare, Kerapept, Keratin" src="consulting/img/split-request.jpg" className="about-img" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="image split-blog-scale">
                                        <img alt="NRN Pharma Cosmetics & Healthcare, Kerapept, Keratin" src="consulting/img/split-request2.jpg" className="about-img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 split-box-container-setting text-center">
                            <div className="split-container-setting">
                                <div className="main-title text-center text-lg-left mb-lg-4 wow fadeIn" data-wow-delay="300ms">
                                    {/* <h5>{request.title}</h5> */}
                                    <h2 className="mb-2rem">
                                        {
                                            strings.request.header.map((item, index) => {
                                                return item.isBold ? <b key={index}>{" "}{item.text}{" "}</b> : <label key={index}>{" "}{item.text}{" "}</label>
                                            })
                                        }
                                    </h2>
                                    {/* <h5>{request.subTitle}</h5> */}
                                </div>
                                <div className="request-form">
                                    <form id="requestForm" className="request-form-outer">
                                        <div className="row">
                                            <div className="col-sm-12" id="result" />
                                            <div className="col-md-12 col-sm-12">
                                                <div className="request-form-textfield pb-4">
                                                    <select defaultValue={0} className="form-control" title="subject" name="subject" id="services" required>
                                                        {
                                                            strings.request.dropdown.map((item, index) => {
                                                                return <option disabled={index === 0 ? true : false} key={index} value={index}>{item}</option>
                                                            })
                                                        }

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="request-form-textfield pb-4">
                                                    <input type="text" placeholder={strings.request.inputs.company} className="form-control mb-0" required id="company" name="company" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="request-form-textfield pb-4">
                                                    <input type="text" placeholder={strings.request.inputs.name} className="form-control mb-0" required id="name" name="name" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="request-form-textfield pb-4">
                                                    <input type="text" placeholder={strings.request.inputs.surname} className="form-control mb-0" required id="surname" name="surname" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="request-form-textfield pb-4">
                                                    <input type="tel" placeholder={strings.request.inputs.number} className="form-control mb-0" required id="phone" name="phone" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="request-form-textfield pb-4">
                                                    <input type="email" placeholder={strings.request.inputs.email} className="form-control mb-0" required id="email" name="email" />
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="request-form-textfield pb-4">
                                                    <textarea type="text" placeholder={strings.request.inputs.description} className="form-control mb-0" required id="description" name="description" />
                                                </div>
                                            </div>
                                            <input type="hidden" id="currentLanguage" required name="language" value={currentLanguageCode} />
                                            <div className="col-lg-12 pt-xs-25px text-lg-left text-center">
                                                <button id="requestButton" type="submit" className="btn-setting btn-scale btn-blue text-white">{strings.request.inputs.send}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*Request ends*/}

            {/* Request Boxes start */}
            {/* <section>
                <div className="container">
                    <div className="row">
                        {
                            request.buttons.map((item, index) => {
                                return <div key={index} className="col-lg-3 col-sm-6 col-12">
                                    <div className="process-box text-sm-left mb-4 mb-lg-0">
                                        <span className="pro-step d-inline-block"><i className={item.icon} /></span>
                                        <h5 className="font-weight-normal color-black mt-25px mb-15px text-capitalize">{item.header}</h5>
                                        <p className="font-weight-normal">{item.text}</p>
                                    </div>
                                </div>
                            })
                        }

                    </div>
                </div>
            </section> */}
            {/* Request Boxes end */}


        </Fragment>
    ) : null;
}

const mapStateToProps = state => {
    return {
        home: state.home
    }
}

export default connect(mapStateToProps, {

})(multilanguage(Team));